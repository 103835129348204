import React, { useState, useEffect } from 'react';
import './index.css'; // Ensure this file contains the CSS animation
const fallenStar = require("./blinking-stars.svg").default;

const getRandomPosition = () => {
  const top = Math.random() * 100;
  const left = Math.random() * 100;
  return { top, left };
};

const BlinkingStar = () => {
  const [position] = useState(getRandomPosition());

  useEffect(() => {
    const interval = setInterval(() => {
      // Toggle class to trigger animation restart
      const star = document.getElementById("blinking-star");
      star.classList.toggle("blink-on");
      setTimeout(() => {
        star.classList.toggle("blink-on");
      }, 3000);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <img
      id="blinking-star"
      src={fallenStar}
      className="blinking-star"
      style={{
        top: `${position.top}vh`,
        left: `${position.left}vw`,
      }}
      alt="Blinking Star"
    />
  );
};

export default BlinkingStar;

