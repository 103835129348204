import React from "react";
import Tilt from "react-parallax-tilt";
import { Button } from "react-bootstrap";

import { ClaimCoin } from "./claim-coin";

import "./index.css";

export const ClaimButton = () => {
  const createDoubleTapPreventer = (timeout_ms) => {
    let dblTapTimer = 0;
    let dblTapPressed = false;

    return function (e) {
      clearTimeout(dblTapTimer);
      if (dblTapPressed) {
        e.preventDefault();
        dblTapPressed = false;
      } else {
        dblTapPressed = true;
        dblTapTimer = setTimeout(() => {
          dblTapPressed = false;
        }, timeout_ms);
      }
    };
  };

  React.useEffect(() => {
    document.body.addEventListener(
      "touchstart",
      createDoubleTapPreventer(500),
      { passive: false }
    );
  }, []);

  const [clicks, setClicks] = React.useState([]);

  const [[manualTiltAngleX, manualTiltAngleY], setManualTiltAngle] = React.useState([0, 0]);

  const handleTouchStart = (event) => {
    event?.preventDefault?.();

    const { clientX, clientY } = event.changedTouches[0];
    const buttonRect = event.target.getBoundingClientRect();
    const percentX = (clientX - buttonRect.left) / buttonRect.width;
    const percentY = (clientY - buttonRect.top) / buttonRect.height;
    let tiltAngleX = (percentY - 0.5) * 20;
    let tiltAngleY = (percentX - 0.5) * 20 * -1;
    setManualTiltAngle([tiltAngleX, tiltAngleY]);
  };

  const handleTouchEnd = (event) => {
    event?.preventDefault?.();

    const { clientX, clientY } = event.changedTouches[0];

    // Add new click to state
    setClicks((prevClicks) => [
      ...prevClicks,
      { id: Math.random(), x: clientX, y: clientY },
    ]);

    // Remove click from state after animation ends
    setTimeout(() => {
      setClicks((prevClicks) => prevClicks.slice(1));
    }, 1000);

    setTimeout(() => {
      setManualTiltAngle([0, 0]);
    }, 100);
  };

  const handleClick = (event) => {
    event?.preventDefault?.();

    const { clientX, clientY } = event;

    // Add new click to state
    setClicks((prevClicks) => [
      ...prevClicks,
      { id: Math.random(), x: clientX, y: clientY },
    ]);

    // Remove click from state after animation ends
    setTimeout(() => {
      setClicks((prevClicks) => prevClicks.slice(1));
    }, 1000);

    setTimeout(() => {
      setManualTiltAngle([0, 0]);
    }, 100);
  };

  const handleStart = (event) => {
    if (event.type === "touchstart") {
      handleTouchStart(event);
    } else if (event.type === "mousedown") {
      handleClick(event);
    }
  };

  const handleEnd = (event) => {
    if (event.type === "touchend") {
      handleTouchEnd(event);
    } else if (event.type === "mouseup") {
      handleClick(event);
    }
  };

  return (
    <div className="claim-button-root">
      <Button
        className="claim-button"
        onTouchStart={handleStart}
        onTouchEnd={handleEnd}
        onMouseDown={handleStart}
        onMouseUp={handleEnd}
      >
        <Tilt
          tiltAngleXManual={manualTiltAngleX}
          tiltAngleYManual={manualTiltAngleY}
          tiltReverse={true}
          glareEnable={false}
          glareMaxOpacity={0.7}
          glareColor="#ffffff"
          glarePosition="bottom"
          glareBorderRadius="50%"
        >
          <ClaimCoin />
        </Tilt>
      </Button>
      {clicks.map((click) => (
        <div
          key={click.id}
          className="flyAndFade ab"
          style={{ position: "fixed", left: click.x, top: click.y }}
        >
          +100
        </div>
      ))}
    </div>
  );
};
