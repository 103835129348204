import React, { useEffect, useState, memo } from 'react';
import '../index.css'; // Ensure this file contains the CSS animation
const fallenStar = require("../img/fallen-star.svg").default;

const FlyingStarComponent = () => {
  const [showStar, setShowStar] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowStar(true);
      setTimeout(() => {
        setShowStar(false);
      }, 3000); // Duration of the animation
    }, 8000); // Interval for the animation

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {showStar && <img src={fallenStar} className="star fly" />}
    </div>
  );
};

export const FlyingStar = memo(FlyingStarComponent);
