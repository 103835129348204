import React from 'react';
import BlinkingStar from './blinking-star';

const BlinkingStars = () => {
  const stars = Array.from({ length: 20 });

  return (
    <div>
      {stars.map((_, index) => (
        <BlinkingStar key={index} />
      ))}
    </div>
  );
};

export default React.memo(BlinkingStars);
