import "./App.css";
import { ClaimButton } from "./claim-button";
import { FlyingStar } from './fallen-star';
import BlinkingsStars from './blinkings-stars';


const mainBg = require("./img/main-bg.png");
const text = require("./img/text.svg").default;
const wcoinIcon = require("./img/w-coin.svg").default;
const xIcon = require("./img/x.svg").default;
const tgIocn = require("./img/tg.svg").default;

function App() {
  return (
    <div className="app" style={{ backgroundImage: `url(${mainBg})` }}>
      <div className="app-wrapper">
        <ClaimButton />

        <div className="app-info-area">
          <img src={text}  width="330px" />
          <div className="app-social-wrapper">
            <a href="https://t.me/wcoin_tapbot" target="_blank" rel="noreferrer">
              <img src={wcoinIcon} />
            </a>
            <div className="divider"></div>
            <a href="https://x.com/wcoin_io" target="_blank" rel="noreferrer">
              <img src={xIcon} />
            </a>
            <div className="divider"></div>
            <a href="https://t.me/w_community" target="_blank" rel="noreferrer">
              <img src={tgIocn} />
            </a>
          </div>
        </div>
      </div>

      <div className="reserved">
        © 2024 All rights reserved.
      </div>

      {/* <FlyingStar /> */}
      <BlinkingsStars />
    </div>
  );
}

export default App;
